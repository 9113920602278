/**
* Generated automatically at built-time (2025-04-01T08:24:28.607Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "skeldervik-outdoorkueche",templateKey: "sites/102-64a7bae8-cdeb-4047-b236-9ced72c45e1f"};